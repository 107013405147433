// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
.auth {
  display: flex;
  justify-content: center;
  padding-top: 2vh;
  .auth-container {
    animation: show 0.2s ease-in;
    width: 95%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    min-height: calc(100vh - 5rem);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    .contenedor-arte {
      width: 40%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contenedor-formulario {
      animation: fade 1.3s ease-in;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      gap: 1rem;
      h1 {
        text-align: center;
        font-size: clamp(1.5rem, 2vw, 2.3rem);
        span {
          color: $color-primario;
          font-weight: 800;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        width: 90%;
        max-width: 600px;
        gap: 1rem;

        p {
          font-weight: 500;
          color: $color-primario;
          text-align: center;
          &:hover {
            cursor: pointer;
            color: $color-primario-contraste;
          }
        }
      }

      hr {
        width: 20%;
        opacity: 0.2;
      }

      p {
        span {
          color: $color-primario;
          font-weight: 500;
          &:hover {
            cursor: pointer;
            color: $color-primario-contraste;
          }
        }
      }
    }
  }
}

.verificacion {
  display: flex;
  padding: 2rem;

  width: 100%;
  height: 100vh;
  flex-direction: column;
  gap: clamp(1rem, 2vw, 2rem);
  align-items: center;
  justify-content: center;

  h1 {
    color: $color-primario;
    font-weight: 650;
    font-size: clamp(1.2rem, 4vw, 2.6rem);
  }

  .contenedor-verificacion {
    background-color: #f2f2f2;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: clamp(1rem, 2vw, 2rem);
    border-radius: 20px;
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: clamp(1rem, 2vw, 2rem);

    h3 {
      font-size: clamp(1rem, 2vw, 1.8rem);
      font-weight: 500;
      color: black;
      text-align: center;
    }
    p{
      text-align: center;
    }
  }

  .contenido-verificacion {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    overflow-x: visible !important;
    overflow-y: visible !important;

    .input-verificacion {
      overflow-x: visible !important;
      overflow-y: visible !important;
    }
  }

  span {
    font-weight: 500;
    color: $color-primario;
    &:hover {
      cursor: pointer;
      color: $color-primario-contraste;
    }
  }
  
  .texto-reenvio{
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .btn-verificacion {

    &:hover {
      background-color: $color-primario-contraste;
    } 
  }
  
}

.recuperacion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95vh;
  padding: 2rem;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  gap: 2rem;

  h1 {
    color: $color-primario;
    font-weight: 650;
    font-size: clamp(1.2rem, 4vw, 2.6rem);
  }

  .recuperacion-correo {
    display: flex;
    flex-direction: column;
    animation: fade 1.3s ease-in;
    gap: 1.5rem;
    padding: 4rem;
    background-color: #f2f2f2;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .recuperacion-contrasena {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    animation: fade 1.3s ease-in;
    display: none;
    padding: 4rem;
    background-color: #f2f2f2;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .input-recuperacion {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    width: 100%;
  }

  .input-contrasena {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 230px !important;

  }

  .contenido-verificacion{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

  }

  .boton-recuperacion {
    background-color: $color-primario;
  }

  .boton-verificacion{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $color-primario;
    width: 40%;
  }
}

@media screen and (max-width: 1790px) {
  .auth {
    .auth-container {
      flex-direction: column;

      .contenedor-arte {
        display: none;
      }

      .contenedor-movil {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 50px;

        h1 {
          text-align: center;
          font-size: clamp(1.5rem, 2vw, 2.5rem);
          span {
            color: $color-primario;
            font-weight: 800;
          }
        }
      }
      .contenedor-formulario {
        width: 100%;
        height: 56vh;
        overflow: hidden;
      }
    }
    .contenedor-formulario {
      h1 {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .input-recuperacion {
    flex-direction: column !important;
    gap: 1rem;
    width: 100%;
  }
}
