// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
@import "./variables";
@import "./componentes";
@import "../Componentes/componentes.scss";
@import "../Paginas/Inicio/Inicio.scss";
@import "../Paginas/paginas";
@import "../Auth/Auth/auth";
@import "../Portal/portal";
@import "../Estilos/animaciones";

*{
    font-family: 'Poppins', sans-serif;

    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
}

h1{
    font-family: 'Red Hat Display', sans-serif;
}

.swal2-icon {
    overflow: visible;
}

.MuiFormControl-root{
    overflow: visible !important;
    
    *{
        overflow: visible !important;
    }
}