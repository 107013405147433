.nav-portal{
    background-color: $color-primario;
    display:  flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    color: white;
    .contendor-opciones{
        display: flex;
        align-items: center;
        gap: 10px;

        *{
            font-size: clamp(.7rem, 2vw, 1rem);
            font-weight: 500;
            &:hover{
                cursor: pointer;
                color: $gris-hover;
            }
        }
    }
    
}