// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
@keyframes transformar {
    0% {
       // opacity: 0;
       opacity: 0;
       transform: scale(.9);
    }
    100% {
       opacity: 1;
    }
}

@keyframes fade {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
    
}