// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
.titulo {
    margin-top: 70px;
    color: $color-primario-200;
    font-size: 2rem;
    text-align: center;
  }

  .preguntas {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center; 
    padding-bottom: 20px;
    
  }

.pregunta-frecuente {
    display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 900px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }

 
  
  .icono{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    
  }
 

  .pregunta {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 80%;
    gap: 1rem;
    p {
      font-size: clamp(0.8rem, 2.5vw, 1.1rem);
    }
    img{
      max-width: 80px;
      }
  }

  .respuesta {
    display: flex;
 
    color: #1e1e1e;
    font-style: normal;
    align-items: center;
    text-align: left;
    font-weight: 400;
    overflow: hidden;
    max-height: 0px;
    height: auto;
    width: 90%;
   
    
    transition: all 0.5s ease-in-out;
    p {
      font-size: clamp(0.8rem, 2.5vw, 1.1rem);
    }
  }

  .boton-preguntas {
    display: flex;
    justify-content: right;
    align-items: right;
    stroke-width: 70px !important;
    stroke: #292d32 !important;
  }

 }




@media screen and (max-width: 900px) {
  .preguntas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;

    text-align: center;
    flex-wrap: wrap;
  }

  .Mapas{

    .Mapa-CUU{
      width: 100%;
      height: 300px;
    }

    .Mapa-JRZ{
      width: 100%;
      height: 300px;
    }
  }
}

