// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
button {
  border: none;
  background-color: $color-quinto;
  padding: 0.5rem 1rem;
  border: solid 1px white;
  font-weight: 500;
  color: white;
  border-radius: clamp(5px, 10%, 15px);
  &:hover {
    background-color: $color-quinto-contraste;
    cursor: pointer;
  }
}

.opcion-multiple {
  .contenedor-opciones {
    margin-top: 10px;
    justify-content: flex-start;

    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
 
  }

  .opcion {
    min-width: 150px;
    width: 90%;
    max-width: 200px;
    background-color: rgba($color: $color-primario, $alpha: 0.2);
    padding: clamp(10px, 3vw, 12px);
    border-radius: 5px;
    text-align: center;
    transition: all 0.2s ease-in;

     font-size: clamp(0.8rem, 1vw, .8rem);
    
    &:hover {
      background-color: rgba($color: $color-primario, $alpha: 0.4);
      cursor: pointer;
    }
  }
  .seleccion {
    background-color: $color-primario;
    color: white;
    &:hover {
      background-color: $color-primario;
    }
  }
}

.date-picker {
  .contenedor-fecha {
    margin-top: 10px;

    display: flex;
    gap: 1rem;
    * {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

.etapa-formulario {
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .etapa {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    width: 100%;
    transition: all 0.3s ease-in;
    form {
      
      padding: 1rem;
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .contenedor-doble {
        display: flex;
        gap: 1rem;
        * {
          flex-grow: 1;
          flex-basis: 0;
        }
      }
    }
  }
}

.desplegable {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .controlador {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
  }
  .contenedor-desplegable {
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: all 0.3s ease-in;
  }
}

.contenedor-indicador-etapas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease-in;
  gap: 5px;
  .indicador-etapa {
    $tamano: 9px;
    width: $tamano;
    height: $tamano;
    background-color: darken($color: $gris-hover, $amount: 20%);
    border-radius: 50%;
  }

  .etapa-seleccionada {
    background-color: $color-primario;
    $tamano: 10px;
    width: $tamano;
    height: $tamano;
  }
}


.secuencia-etapas{
  padding: .5rem;
  margin-bottom:10px;
  width: 100%;
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 10px 1fr 10px 1fr;
  place-items: center;
  
  gap: 1rem;
  .etapa-secuencia{
    color: $color-primario;
    display: flex;
    align-items: center;
    gap: 10px;
    p{
      font-size: clamp(.6rem, 1vw, .8rem);
    }
    &:hover{
      cursor: pointer;
    
      font-weight: 500;
    }
  }
  .incompleta{
    color: darken($color: $gris-hover, $amount: 30%);
  }
  .activo{
    p{
      font-weight: 600;
    }
  }
}