// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
.footer {
  color: #fff;
  background-color: #d6506d;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  padding: 30px;
}

.footer-logo {
  display: flex;
  justify-content: center;
  flex-basis: 20%;

  img {
    display: flex;
    width: 210px;
    height: 170px;
    justify-content: center;
    align-items: center;
  }
}

.footer-contact {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  margin-right: 20px;

  .icono{
    margin-right: 1rem;
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  padding: 30px;
  flex-basis: 10%;
  border-left: solid 2px #fff;
  gap: 0.5rem;

  p {
    text-align: left;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    text-transform: capitalize;
    transition: color 0.3s;

    &:hover {
      color: #bc2449;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-contact {
    text-align: center;
    align-items: center;
  }

  .footer-contact {
    order: 1;
    justify-content: center;
    margin-right: 0px;
  }

  .footer-links {
    margin: 30px;
    border-left: solid 0px #fff;
    margin-bottom: 30px;
    gap: 1rem;
    p {
      text-align: center;
    }
  }
}
