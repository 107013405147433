// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
.main {
  .contenedor-slider-escritorio {
    width: 100vw;
    overflow-y: hidden !important;
    overflow-x: hidden;

    .slide {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .texto-slider {
        color: #a92e41;
        top: 200px;
        left: 150px;
        height: 60%;
        width: 40%;
        position: absolute;
        z-index: 2;
        font-size: clamp(0.9rem, 2vw, 2.5rem);
        font-weight: 600;

        .boton {
          background-color: #e17100;
          border-radius: 5px;
          position: absolute;
          font-weight: 600;
          font-size: clamp(0.9rem, 2vw, 1.8rem);
          justify-content: left;
          position: absolute;
          z-index: 2;
        }
      }
    }
  }

  .slide2 {
    position: relative; /* Establece la posición relativa en el contenedor del slide */
  }

  .slide-content2 {
    position: absolute; /* Establece la posición absoluta para el contenido */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10%; /* Establece el ancho del contenido */
    top: 10%; /* Establece la distancia desde la parte superior según tu preferencia */
    left: 10%; /* Establece la distancia desde la izquierda según tu preferencia */
    transform: translate(-50%, -50%); /* Centra el contenido en ambos ejes */
    text-align: center; /* Centra el texto horizontalmente */
  }

  .slide2 img {
    width: 100%; /* Ajusta la imagen al ancho del slide */
    height: 100%; /* Ajusta la imagen a la altura del slide */
    object-fit: cover; /* Ajusta la imagen para que llene el espacio del slide */
  }

  .slider2 {
    display: none;
  }

  .pop-up {
    position: fixed;
    background-color: #e84e1c;
    z-index: 20;
    width: 240px;
    right: 0px;
    border-radius: 10px;
    border-color: white;
    border-style: solid;
    top: 60vh;

    .pop-up-contenido {
      z-index: 20;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: white;
    }
  }

  .descripcion {
    position: relative;
    background-color: $color-primario;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    gap: clamp(1rem, 10vw, 4rem);
    overflow: hidden;
    .fondo {
      opacity: 0.6;
      top: 0px;
      left: -10vw;
      width: 300px;
      z-index: 0;
      position: absolute;
      img {
        width: 100%;
      }
    }
    .contenedor-emblema {
      z-index: 2;
      width: 25vw;
      min-width: 200px;
      max-width: 350px;
      img {
        width: 100%;
      }
    }

    .contenedor-texto {
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 500px;
      color: white;
      h3 {
        font-size: clamp(1.5rem, 5vw, 2rem);
      }
      p {
        font-size: clamp(0.8rem, 7vw, 1.2rem);
      }
    }
  }

  .secciones {
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    .seccion {
      border-radius: 2rem;
      width: 400px;
      display: flex;
      flex-direction: column;
      box-shadow: 5px 5px 10px rgba(0, 0, 0.25, 0.25);
      .contenedor-imagen {
        overflow-y: hidden !important;
        width: 100%;
        height: 200px;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      .cuerpo-seccion {
        background-color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        height: calc(100% - 200px);
        text-align: center;
        padding: 1rem 2rem;
        color: $gris-contenido;

        .titulo-paraquesirve {
          font-size: clamp(1.2rem, 5vw, 1.5rem);
          font-weight: 600;
          color: $negro-titulo;
        }

        .titulo-mercadolibre {
          font-size: clamp(1.2rem, 5vw, 1.5rem);
          font-weight: 600;
          color: $negro-titulo;
        }

        .titulo-beneficios {
          font-size: clamp(1.2rem, 5vw, 1.5rem);
          font-weight: 600;
          color: $negro-titulo;
        }

        .contenido-beneficios {
          text-align: left;
        }
      }
    }
  }

  .contenedor-sectores {
    background-color: white;

    .titulo-sectores {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      color: $negro-titulo;
      background-clip: text;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.92px;
      padding: 20px;
    }
  }
  .sectores {
    display: flex;
    justify-content: center;
    padding: 2rem;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    gap: 3rem;

    .sector {
      display: flex;
      flex-direction: column;
      background: #e95079;
      border-radius: 15px;
      max-width: 265px;
      width: 100%;

      .ilustracion {
        width: 100%;
        height: 125px;
        overflow: hidden;
        position: relative;
        display: grid;
        place-items: center;

        .sector-fondo {
          position: absolute;
          z-index: 0;
          width: 100%;
        }

        .sector-icono {
          position: relative;
          z-index: 3;
          object-fit: fill;
          width: 30%;

          border-radius: (15px 15px 1px 1px);
          box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.2);
        }
      }

      p {
        font-size: 20px;
        color: white;
        text-align: center;
        justify-content: center;
        align-items: center;
        border-radius: (0px 0px 15px 15px);
      }
    }
  }

  .contenedor-secciones {
    overflow-y: hidden;

    background-color: #fdcbd1;
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .seccion {
      margin-top: 40px;
      margin-bottom: 40px;
      background-color: white;
      flex-direction: column;
      width: 25%;
      border-radius: 15px;
      display: flex;
      flex-wrap: wrap;

      .imagen-seccion {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
        border-radius: (15px 15px 1px 1px);
      }
      .mostrar-texto {
        display: none;
      }
      .texto-seccion {
        padding: 20px;
      }

      img {
        object-fit: fill;
        height: 100%;
        width: 100%;
        border-radius: (15px 15px 1px 1px);
      }

      p {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%; /* 69px */
        letter-spacing: -0.92px;
        padding: 20px;
        color: #626262;
      }

      h3 {
        justify-content: center;
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%; /* 69px */
      }

      li {
        margin-left: 20px;
      }

      .contenedor-boton {
        display: flex;
        text-align: center;
        justify-content: center;
      }

      .boton2 {
        margin-top: 20px;
        color: #02033b;
        background-color: #f9d012;
        border-radius: 30px;
        font-weight: 600;
        width: 50%;
        height: 40px;
      }
    }

    .boton-responsivo {
      display: none;
    }
  }

  .disclaimer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #000000;
  }

  .contenedor-pasos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .pasos-titulo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 50px;
      color: $negro-titulo;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.92px;
      padding: 20px;
    }

    .pasos-contenido {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
    }

    .contenido {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      width: 10%;
      min-width: 200px;

      p {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        text-transform: uppercase;
        font-size: clamp(1rem, 1.3vw, 1.1rem);
        font-weight: 600;
      }

      img {
        width: 50%;
      }
    }

    .boton3 {
      margin-top: 20px;
      background-color: #e95079;
      border-radius: 15px;
      font-weight: 600;
      width: 10%;
      font-size: 17px;
      margin-bottom: 50px;
      min-width: 200px;
    }
  }

  .contenedor-vigencia {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(148, 147, 147, 0.17);
    padding: 50px;
    gap: 20px;

    h1 {
      color: $negro-titulo;
      display: flex;
      text-align: center;
      justify-content: center;
    }

    .vigencia-duracion {
      display: flex;
      justify-content: center;
      width: clamp(200px, 50vw, 500px);

      background-color: #bc2449;
      font-size: 30px;
      font-weight: 600;
      color: white;

      p {
        color: white;
        text-align: center;
      }
    }

    .disclaimer-vigencia {
      color: #bc2449;
      font-size: clamp(1.3rem, 1.5vw, 2rem);
      font-weight: 600;
      width: 95%;

      text-align: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .contenedor-slider-escritorio {
    display: none !important;
  }
  .slider2 {
    display: block !important;
  }
  .contenedor {
    .contenedor_imagen_fondo_1,
    .contenedor_imagen_fondo_2 {
      display: none !important;
    }
    .imagen {
      margin-left: 0px !important;
    }
    .texto {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 187px;

      h1 {
        text-align: right;
      }
    }

    .seccion {
      .mostrar-texto {
        display: flex;
      }
    }
  }

  .secciones {
    .seccion {
      justify-content: center;
      text-align: center;
      width: 90% !important;
      background-color: #f9d012;
    }
  }

  .contenedor-sectores {
    .sector {
      background: #e95079;
      border-radius: 15px;
      max-width: 265px;
      width: 100%;

      img {
        object-fit: cover;
      }
    }
  }

  .contenedor-pasos {
    .pasos-contenido {
      .contenido {
        flex-direction: row;
        width: 40%;
        padding: 20px;

        img {
          width: 30%;
        }

        p {
          width: 69%;
          padding: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contenedor {
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: -9px !important;
    margin-bottom: 0px !important;
    align-items: center;
    text-align: center;

    .imagen {
      margin-left: 0px !important;
      width: 0% !important;
      width: 100% !important;
      display: flex;
      justify-content: center !important;
    }
    .texto {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px;

      h1 {
        text-align: center;
      }
    }
  }

  .Slider {
    display: none;
  }

  .slider2 {
    display: block !important;
  }

  .pop-up {
    display: none;
  }
  .contenedor-secciones {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .seccion {
      justify-content: center;
      text-align: center;
      width: 57% !important;
      animation: show 1.2s ease-in-out;
    }

    .boton-responsivo {
      display: block !important;
    }

    .texto-seccion {
      animation: show 1.2s ease-in-out;
    }
  }

  .contenedor-pasos {
    .pasos-contenido {
      flex-direction: row;
      justify-content: center;
      .contenido {
        flex-direction: row;
        width: 100%;

        img {
          width: 16%;
        }

        p {
          width: 70%;
        }
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
