// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
nav {
  display: flex;
  width: 100vw;
  background-color: $color-primario;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem 0px;

  .contenedor-logotipo-responsivo {
    display: none;
  }
  .menu {
    display: flex;
    align-items: center;
    button {
      background: none;
      border: none;
      color: white;
      &:hover {
        cursor: pointer;
        color: $gris-hover;
      }
    }
  }

  .llamado-accion {
    button {
      background-color: white;
      border: none;
      color: $color-primario;
      font-weight: 600;
      border-radius: 5px;
    }
  }

  .redes-sociales {
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    gap: clamp(0.8rem, 3vw, 1.1rem);
    font-size: clamp(0.9rem, 5vw, 1.1rem);
   
    
    }

    .icono:hover {
      cursor: pointer;
      color: $gris-hover;
    }

  }
  .contenedor-logotipo {
    width: 12%;
    overflow: hidden;
    max-width: 200px;

    img {
      width: 100%;
    }
  }

  .menu-responsivo {
    display: none;
  }


aside{
  background-color: $color-primario;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-height: 0px;
  transition: all 0.5s ease-in-out;
  button{
    background: none;
    border: none;
    color: white;
    font-weight: 600;
    font-size: clamp(.9rem,5vw,1.1rem);
    border-radius: 5px;
    padding: 0.5rem 1rem;
  }

  .redes-sociales{
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    gap: clamp(0.8rem,3vw,1.1rem);
    font-size: clamp(0.9rem,5vw,1.1rem);
  }

}

@media (max-width: $breakpoint-tablet) {
  nav {
    justify-content: space-between;
    padding: 0.8rem 1.2rem;

    .contenedor-logotipo-responsivo {
      display: block;
      width: 35vw;
      max-width: 200px;
      min-width: 120px;
      img {
        width: 100%;
      }
    }

    .menu {
      display: none;
    }
    .llamado-accion {
      display: none;
    }
    .redes-sociales {
      display: none;
    }
    .contenedor-logotipo {
      display: none;
    }

    .menu-responsivo {
      display: block;
      background: none;
      border: none;
      font-size: clamp(1.2rem, 20vw, 1.8rem);
    }
  }
}
