.landing-portal{
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        text-align: center;
    }
    .contenedor-etapas-solicitud{
        margin-top: 1rem;
        display: flex;
        width: 90%;
        max-width: 1200px;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;

        .etapa-solicitud{
            
            width: 90%;
            max-width: 400px;
            transition: all .3s ease-in-out;
            display: flex;
            background-color: rgba($color: $color-terciario-200, $alpha: .2);
            border-radius: 1rem;
            padding: 1rem;
            gap: 1rem;
            align-items: center;
            &:hover{
                cursor: pointer;
                transform: scale(1.02);
                background-color: rgba($color: $color-terciario-200, $alpha: .34);
            }


        }

        .alerta{
            transition: all .3s ease-in-out;
            transform: scale(1.02);
            background-color: rgba($color: $color-terciario-200, $alpha: .5);
        }

        .bloqueado{
            transition: all .3s ease-in-out;
            transform: scale(1.02);
            background-color: $gris-hover;
            &:hover{
                cursor: not-allowed;
            }
        
        }
    }

    .error-alerta{
        animation: show .5s ease-in-out;
        color: $color-terciario;
    }
}