// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
$color-primario-100:#EF7D8E;
$color-primario-200:#E44F69;
$color-primario-300:#D82144;
$color-primario:#D6506D;
$color-primario-contraste:#921F3F;


$color-secundario-100:#FBD0D9;
$color-secundario-200:#F7AABA;
$color-secundario-300:#F27A97;
$color-secundario:#E95079;
$color-secundario-contraste:#BC2449;

$color-terciario-100:#FDA4D3;
$color-terciario-200:#FB6CB4;
$color-terciario-300:#F44096;
$color-terciario:#E41E73;
$color-terciario-contraste:#C61059;

$color-cuarto-100:#F8CBB0;
$color-cuarto-200:#F3A77E;
$color-cuarto-300:#EE7849;
$color-cuarto:#E84E1C;
$color-cuarto-contraste:#DA3E1C;

$color-quinto-100:#FFE586;
$color-quinto-200:#FFD146;
$color-quinto-300:#FFBC1C;
$color-quinto:#F19001;

$color-quinto-contraste:#E17100;

$black:#646464;

$gris-hover:#e8e8e8;

$negro-titulo:#232323;

$gris-contenido:#4a4a4a;



$breakpoint-tablet:1120px;