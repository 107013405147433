// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
.Contenedor-contacto{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .Contactanos{
        width: 100%;
        display: flex;
        flex-direction: column;
        
        gap: 1rem;
        padding: 50px;



        h1{
            font-size: clamp(1.4rem, 5vw, 2.5rem);
            color: $color-secundario;
            text-align: left;
        }
        h2{
            font-size: clamp(1.4rem, 5vw, 1.6rem);
            color: $color-secundario;
            text-align: left;
        }
        p{
            font-size: clamp(.9rem, 3vw, 1.1rem);
            color: black;
            
        }

        .Formulario{
            gap: 2rem;
            display: flex; 
            flex-direction: column;
            width: 100%;
            padding: 1rem;


            
        }

        .boton-contacto{
        margin-top: 20px;
           background-color: $color-secundario;
           width: 100%;
           display: flex;
           justify-content: center;
              align-items: center;
           gap: 1rem;
           
        }
    }

    .Mapas{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        position: relative;
        overflow: hidden;
        background-color: #f2f2f2;
        

        h2{
            font-size: clamp(1.4rem, 5vw, 1.6rem);
            color: $negro-titulo;
            text-align: left;
        }

        .Mapa-CUU{
            display: flex;
            flex-direction: column;
            width: 70%;
            height: 100%;
            background-color: #f2f2f2;
            animation : fade 1s ease-in-out;

        }
        .Mapa-JRZ{
            display: flex;
            flex-direction: column;
            width: 70%;
            height: 100%;
            background-color: #f2f2f2;
            animation : fade 1s ease-in-out;

        }
    }

    
}

@media screen and (max-width: 1500px) {


    .Contenedor-contacto{
        flex-direction: column;
        gap: 1rem;
        .Contactanos{
            padding: 20px;
            .Formulario{
                gap: 1rem;
            }
        }
    }
}