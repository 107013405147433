// Hugo Edibray Becerra Gandara
// Github: @Edibraaay
.que-es{
    display: flex;
    flex-direction: column; 
    
    align-items: center;
    .resumen{
        display: flex;

        margin-top: 1rem;
        border-radius: 20px;
        padding: 1rem;
        width: 95%;
        background-color: $color-secundario;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 1rem;
        height: auto;
        .contenedor-texto{
            display: flex;
            gap: 1rem;
            flex-direction: column;
            width: clamp(200px, 90%, 500px);
            justify-content: center;
            
            h1{
                text-align: center;
                font-size: clamp(1.4rem, 5vw, 2.5rem);
                color: white;
                font-weight: 1100;
            }
            p{
                font-size: clamp(.9rem, 3vw, 1.1rem);
                color: white;
            }
        }
        .gird-imagenes{
            overflow-y: hidden;

            width: clamp(200px, 90%, 500px);
            display: grid;
            grid-template-columns: repeat(3,1fr);
            gap: clamp(10px, 5%, 20px);
            grid-template-rows: repeat(3,1fr);
            padding-bottom: 1rem;
            padding: 2rem;

            img{
                width: 95%;
                animation : transformar 1s ease-in-out;
                object-fit: cover;
                aspect-ratio: 1/1.12;
                background-color: #f2f2f2;
                border-radius: 1rem;
                
            }
        }

    }

    .objetivo{
        display: flex;

        margin-top: 1rem;
        border-radius: 20px;
        padding: 1rem;
        width: 95%;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 1rem;
        height: auto;
        .img{
            
            width: 80%;
            max-width: 420px;
            aspect-ratio: 1/1;
            border-radius: 50%;
        }
        .contenedor-texto{
            display: flex;
            flex-direction: column;
            width: 90%;
            max-width: 600px;
            padding: 2rem;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            
            h2{
                font-size: clamp(1.4rem, 5vw, 2.5rem);
                color: $color-secundario;
                text-align: center;
                
                
            }
            p{
                font-size: clamp(.9rem, 3vw, 1.1rem);
                text-align: center;
                color: $black;
                span{
                    font-weight: 600;
                    color: $color-secundario;
                }
            }
        }
    }

    .beneficios{
        padding: 2rem;
        background-color: $color-secundario-100;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        h2{
            width: 90%;
            text-align: center;
            max-width: 700px;
            font-size: clamp(1.4rem, 5vw, 2.3rem);
            color: $color-secundario-contraste;
        }
        p{
            width: 90%;
            text-align: center;
            color: $black;
            font-weight: 500;
        }

        .contenedor-beneficios{
            display: grid;
            gap: 1rem;
            width: 80%;

            flex-wrap: wrap;
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: repeat(4,1fr);
            .beneficio{

                background-color: white;
                padding: .4rem 1rem;
                border-radius: 10px;
                display: flex;
                gap: 1rem;
                align-items: center;
                .icono{
                    
                    width: 40px;
                    height: 50px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4.5px;
                }
                h4{
                    font-size: clamp(.75rem, 3vw, .9rem);
                    font-weight: 500;
                    color: $color-primario-contraste;
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    h3{
                        font-size: clamp(1.2rem, 5vw, 1.5rem);
                        color: $color-secundario;
                    }
                    p{
                        font-size: clamp(.7rem, 3vw, 1.1rem);
                        color: $black;
                    }
                }
            }
        }
    }

    .colaboradores{
        background-color: $color-primario;
        padding: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        img{
            width: 90%;
            max-width: 250px;
            height: 100px;
            object-fit: contain;
        }    

    }
}

@media (max-width: 1090px) {
    .que-es{
        .beneficios{
            width: 100%;
            .contenedor-beneficios{
                width:100%;
                grid-template-columns: 1fr;
            }
        }
    }
}