@charset "UTF-8";
button {
  border: none;
  background-color: #F19001;
  padding: 0.5rem 1rem;
  border: solid 1px white;
  font-weight: 500;
  color: white;
  border-radius: clamp(5px, 10%, 15px);
}
button:hover {
  background-color: #E17100;
  cursor: pointer;
}

.opcion-multiple .contenedor-opciones {
  margin-top: 10px;
  justify-content: flex-start;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.opcion-multiple .opcion {
  min-width: 150px;
  width: 90%;
  max-width: 200px;
  background-color: rgba(214, 80, 109, 0.2);
  padding: clamp(10px, 3vw, 12px);
  border-radius: 5px;
  text-align: center;
  transition: all 0.2s ease-in;
  font-size: clamp(0.8rem, 1vw, 0.8rem);
}
.opcion-multiple .opcion:hover {
  background-color: rgba(214, 80, 109, 0.4);
  cursor: pointer;
}
.opcion-multiple .seleccion {
  background-color: #D6506D;
  color: white;
}
.opcion-multiple .seleccion:hover {
  background-color: #D6506D;
}

.date-picker .contenedor-fecha {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
}
.date-picker .contenedor-fecha * {
  flex-grow: 1;
  flex-basis: 0;
}

.etapa-formulario {
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.etapa-formulario .etapa {
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  transition: all 0.3s ease-in;
}
.etapa-formulario .etapa form {
  padding: 1rem;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.etapa-formulario .etapa form .contenedor-doble {
  display: flex;
  gap: 1rem;
}
.etapa-formulario .etapa form .contenedor-doble * {
  flex-grow: 1;
  flex-basis: 0;
}

.desplegable {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.desplegable .controlador {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
}
.desplegable .contenedor-desplegable {
  max-height: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.3s ease-in;
}

.contenedor-indicador-etapas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease-in;
  gap: 5px;
}
.contenedor-indicador-etapas .indicador-etapa {
  width: 9px;
  height: 9px;
  background-color: #b5b5b5;
  border-radius: 50%;
}
.contenedor-indicador-etapas .etapa-seleccionada {
  background-color: #D6506D;
  width: 10px;
  height: 10px;
}

.secuencia-etapas {
  padding: 0.5rem;
  margin-bottom: 10px;
  width: 100%;
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 10px 1fr 10px 1fr;
  place-items: center;
  gap: 1rem;
}
.secuencia-etapas .etapa-secuencia {
  color: #D6506D;
  display: flex;
  align-items: center;
  gap: 10px;
}
.secuencia-etapas .etapa-secuencia p {
  font-size: clamp(0.6rem, 1vw, 0.8rem);
}
.secuencia-etapas .etapa-secuencia:hover {
  cursor: pointer;
  font-weight: 500;
}
.secuencia-etapas .incompleta {
  color: #9c9c9c;
}
.secuencia-etapas .activo p {
  font-weight: 600;
}

nav {
  display: flex;
  width: 100vw;
  background-color: #D6506D;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem 0px;
}
nav .contenedor-logotipo-responsivo {
  display: none;
}
nav .menu {
  display: flex;
  align-items: center;
}
nav .menu button {
  background: none;
  border: none;
  color: white;
}
nav .menu button:hover {
  cursor: pointer;
  color: #e8e8e8;
}
nav .llamado-accion button {
  background-color: white;
  border: none;
  color: #D6506D;
  font-weight: 600;
  border-radius: 5px;
}
nav .redes-sociales {
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  gap: clamp(0.8rem, 3vw, 1.1rem);
  font-size: clamp(0.9rem, 5vw, 1.1rem);
}
nav .icono:hover {
  cursor: pointer;
  color: #e8e8e8;
}

.contenedor-logotipo {
  width: 12%;
  overflow: hidden;
  max-width: 200px;
}
.contenedor-logotipo img {
  width: 100%;
}

.menu-responsivo {
  display: none;
}

aside {
  background-color: #D6506D;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-height: 0px;
  transition: all 0.5s ease-in-out;
}
aside button {
  background: none;
  border: none;
  color: white;
  font-weight: 600;
  font-size: clamp(0.9rem, 5vw, 1.1rem);
  border-radius: 5px;
  padding: 0.5rem 1rem;
}
aside .redes-sociales {
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  gap: clamp(0.8rem, 3vw, 1.1rem);
  font-size: clamp(0.9rem, 5vw, 1.1rem);
}

@media (max-width: 1120px) {
  nav {
    justify-content: space-between;
    padding: 0.8rem 1.2rem;
  }
  nav .contenedor-logotipo-responsivo {
    display: block;
    width: 35vw;
    max-width: 200px;
    min-width: 120px;
  }
  nav .contenedor-logotipo-responsivo img {
    width: 100%;
  }
  nav .menu {
    display: none;
  }
  nav .llamado-accion {
    display: none;
  }
  nav .redes-sociales {
    display: none;
  }
  nav .contenedor-logotipo {
    display: none;
  }
  nav .menu-responsivo {
    display: block;
    background: none;
    border: none;
    font-size: clamp(1.2rem, 20vw, 1.8rem);
  }
}
.footer {
  color: #fff;
  background-color: #d6506d;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  padding: 30px;
}

.footer-logo {
  display: flex;
  justify-content: center;
  flex-basis: 20%;
}
.footer-logo img {
  display: flex;
  width: 210px;
  height: 170px;
  justify-content: center;
  align-items: center;
}

.footer-contact {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  margin-right: 20px;
}
.footer-contact .icono {
  margin-right: 1rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
  padding: 30px;
  flex-basis: 10%;
  border-left: solid 2px #fff;
  gap: 0.5rem;
}
.footer-links p {
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  text-transform: capitalize;
  transition: color 0.3s;
}
.footer-links p:hover {
  color: #bc2449;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }
  .footer-contact {
    text-align: center;
    align-items: center;
  }
  .footer-contact {
    order: 1;
    justify-content: center;
    margin-right: 0px;
  }
  .footer-links {
    margin: 30px;
    border-left: solid 0px #fff;
    margin-bottom: 30px;
    gap: 1rem;
  }
  .footer-links p {
    text-align: center;
  }
}
.main .contenedor-slider-escritorio {
  width: 100vw;
  overflow-y: hidden !important;
  overflow-x: hidden;
}
.main .contenedor-slider-escritorio .slide {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.main .contenedor-slider-escritorio .slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main .contenedor-slider-escritorio .slide .texto-slider {
  color: #a92e41;
  top: 200px;
  left: 150px;
  height: 60%;
  width: 40%;
  position: absolute;
  z-index: 2;
  font-size: clamp(0.9rem, 2vw, 2.5rem);
  font-weight: 600;
}
.main .contenedor-slider-escritorio .slide .texto-slider .boton {
  background-color: #e17100;
  border-radius: 5px;
  position: absolute;
  font-weight: 600;
  font-size: clamp(0.9rem, 2vw, 1.8rem);
  justify-content: left;
  position: absolute;
  z-index: 2;
}
.main .slide2 {
  position: relative; /* Establece la posición relativa en el contenedor del slide */
}
.main .slide-content2 {
  position: absolute; /* Establece la posición absoluta para el contenido */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%; /* Establece el ancho del contenido */
  top: 10%; /* Establece la distancia desde la parte superior según tu preferencia */
  left: 10%; /* Establece la distancia desde la izquierda según tu preferencia */
  transform: translate(-50%, -50%); /* Centra el contenido en ambos ejes */
  text-align: center; /* Centra el texto horizontalmente */
}
.main .slide2 img {
  width: 100%; /* Ajusta la imagen al ancho del slide */
  height: 100%; /* Ajusta la imagen a la altura del slide */
  -o-object-fit: cover;
     object-fit: cover; /* Ajusta la imagen para que llene el espacio del slide */
}
.main .slider2 {
  display: none;
}
.main .pop-up {
  position: fixed;
  background-color: #e84e1c;
  z-index: 20;
  width: 240px;
  right: 0px;
  border-radius: 10px;
  border-color: white;
  border-style: solid;
  top: 60vh;
}
.main .pop-up .pop-up-contenido {
  z-index: 20;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
}
.main .descripcion {
  position: relative;
  background-color: #D6506D;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  gap: clamp(1rem, 10vw, 4rem);
  overflow: hidden;
}
.main .descripcion .fondo {
  opacity: 0.6;
  top: 0px;
  left: -10vw;
  width: 300px;
  z-index: 0;
  position: absolute;
}
.main .descripcion .fondo img {
  width: 100%;
}
.main .descripcion .contenedor-emblema {
  z-index: 2;
  width: 25vw;
  min-width: 200px;
  max-width: 350px;
}
.main .descripcion .contenedor-emblema img {
  width: 100%;
}
.main .descripcion .contenedor-texto {
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 500px;
  color: white;
}
.main .descripcion .contenedor-texto h3 {
  font-size: clamp(1.5rem, 5vw, 2rem);
}
.main .descripcion .contenedor-texto p {
  font-size: clamp(0.8rem, 7vw, 1.2rem);
}
.main .secciones {
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.main .secciones .seccion {
  border-radius: 2rem;
  width: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}
.main .secciones .seccion .contenedor-imagen {
  overflow-y: hidden !important;
  width: 100%;
  height: 200px;
}
.main .secciones .seccion .contenedor-imagen img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.main .secciones .seccion .cuerpo-seccion {
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 200px);
  text-align: center;
  padding: 1rem 2rem;
  color: #4a4a4a;
}
.main .secciones .seccion .cuerpo-seccion .titulo-paraquesirve {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  font-weight: 600;
  color: #232323;
}
.main .secciones .seccion .cuerpo-seccion .titulo-mercadolibre {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  font-weight: 600;
  color: #232323;
}
.main .secciones .seccion .cuerpo-seccion .titulo-beneficios {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  font-weight: 600;
  color: #232323;
}
.main .secciones .seccion .cuerpo-seccion .contenido-beneficios {
  text-align: left;
}
.main .contenedor-sectores {
  background-color: white;
}
.main .contenedor-sectores .titulo-sectores {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  color: #232323;
  -webkit-background-clip: text;
          background-clip: text;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.92px;
  padding: 20px;
}
.main .sectores {
  display: flex;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  gap: 3rem;
}
.main .sectores .sector {
  display: flex;
  flex-direction: column;
  background: #e95079;
  border-radius: 15px;
  max-width: 265px;
  width: 100%;
}
.main .sectores .sector .ilustracion {
  width: 100%;
  height: 125px;
  overflow: hidden;
  position: relative;
  display: grid;
  place-items: center;
}
.main .sectores .sector .ilustracion .sector-fondo {
  position: absolute;
  z-index: 0;
  width: 100%;
}
.main .sectores .sector .ilustracion .sector-icono {
  position: relative;
  z-index: 3;
  -o-object-fit: fill;
     object-fit: fill;
  width: 30%;
  border-radius: 15px 15px 1px 1px;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.2);
}
.main .sectores .sector p {
  font-size: 20px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 15px 15px;
}
.main .contenedor-secciones {
  overflow-y: hidden;
  background-color: #fdcbd1;
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.main .contenedor-secciones .seccion {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: white;
  flex-direction: column;
  width: 25%;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
}
.main .contenedor-secciones .seccion .imagen-seccion {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 15px 15px 1px 1px;
}
.main .contenedor-secciones .seccion .mostrar-texto {
  display: none;
}
.main .contenedor-secciones .seccion .texto-seccion {
  padding: 20px;
}
.main .contenedor-secciones .seccion img {
  -o-object-fit: fill;
     object-fit: fill;
  height: 100%;
  width: 100%;
  border-radius: 15px 15px 1px 1px;
}
.main .contenedor-secciones .seccion p {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 69px */
  letter-spacing: -0.92px;
  padding: 20px;
  color: #626262;
}
.main .contenedor-secciones .seccion h3 {
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%; /* 69px */
}
.main .contenedor-secciones .seccion li {
  margin-left: 20px;
}
.main .contenedor-secciones .seccion .contenedor-boton {
  display: flex;
  text-align: center;
  justify-content: center;
}
.main .contenedor-secciones .seccion .boton2 {
  margin-top: 20px;
  color: #02033b;
  background-color: #f9d012;
  border-radius: 30px;
  font-weight: 600;
  width: 50%;
  height: 40px;
}
.main .contenedor-secciones .boton-responsivo {
  display: none;
}
.main .disclaimer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  color: #000000;
}
.main .contenedor-pasos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.main .contenedor-pasos .pasos-titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
  color: #232323;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.92px;
  padding: 20px;
}
.main .contenedor-pasos .pasos-contenido {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.main .contenedor-pasos .contenido {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 10%;
  min-width: 200px;
}
.main .contenedor-pasos .contenido p {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-transform: uppercase;
  font-size: clamp(1rem, 1.3vw, 1.1rem);
  font-weight: 600;
}
.main .contenedor-pasos .contenido img {
  width: 50%;
}
.main .contenedor-pasos .boton3 {
  margin-top: 20px;
  background-color: #e95079;
  border-radius: 15px;
  font-weight: 600;
  width: 10%;
  font-size: 17px;
  margin-bottom: 50px;
  min-width: 200px;
}
.main .contenedor-vigencia {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(148, 147, 147, 0.17);
  padding: 50px;
  gap: 20px;
}
.main .contenedor-vigencia h1 {
  color: #232323;
  display: flex;
  text-align: center;
  justify-content: center;
}
.main .contenedor-vigencia .vigencia-duracion {
  display: flex;
  justify-content: center;
  width: clamp(200px, 50vw, 500px);
  background-color: #bc2449;
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.main .contenedor-vigencia .vigencia-duracion p {
  color: white;
  text-align: center;
}
.main .contenedor-vigencia .disclaimer-vigencia {
  color: #bc2449;
  font-size: clamp(1.3rem, 1.5vw, 2rem);
  font-weight: 600;
  width: 95%;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .contenedor-slider-escritorio {
    display: none !important;
  }
  .slider2 {
    display: block !important;
  }
  .contenedor .contenedor_imagen_fondo_1,
  .contenedor .contenedor_imagen_fondo_2 {
    display: none !important;
  }
  .contenedor .imagen {
    margin-left: 0px !important;
  }
  .contenedor .texto {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 187px;
  }
  .contenedor .texto h1 {
    text-align: right;
  }
  .contenedor .seccion .mostrar-texto {
    display: flex;
  }
  .secciones .seccion {
    justify-content: center;
    text-align: center;
    width: 90% !important;
    background-color: #f9d012;
  }
  .contenedor-sectores .sector {
    background: #e95079;
    border-radius: 15px;
    max-width: 265px;
    width: 100%;
  }
  .contenedor-sectores .sector img {
    -o-object-fit: cover;
       object-fit: cover;
  }
  .contenedor-pasos .pasos-contenido .contenido {
    flex-direction: row;
    width: 40%;
    padding: 20px;
  }
  .contenedor-pasos .pasos-contenido .contenido img {
    width: 30%;
  }
  .contenedor-pasos .pasos-contenido .contenido p {
    width: 69%;
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .contenedor {
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: -9px !important;
    margin-bottom: 0px !important;
    align-items: center;
    text-align: center;
  }
  .contenedor .imagen {
    margin-left: 0px !important;
    width: 0% !important;
    width: 100% !important;
    display: flex;
    justify-content: center !important;
  }
  .contenedor .texto {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
  .contenedor .texto h1 {
    text-align: center;
  }
  .Slider {
    display: none;
  }
  .slider2 {
    display: block !important;
  }
  .pop-up {
    display: none;
  }
  .contenedor-secciones {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .contenedor-secciones .seccion {
    justify-content: center;
    text-align: center;
    width: 57% !important;
    animation: show 1.2s ease-in-out;
  }
  .contenedor-secciones .boton-responsivo {
    display: block !important;
  }
  .contenedor-secciones .texto-seccion {
    animation: show 1.2s ease-in-out;
  }
  .contenedor-pasos .pasos-contenido {
    flex-direction: row;
    justify-content: center;
  }
  .contenedor-pasos .pasos-contenido .contenido {
    flex-direction: row;
    width: 100%;
  }
  .contenedor-pasos .pasos-contenido .contenido img {
    width: 16%;
  }
  .contenedor-pasos .pasos-contenido .contenido p {
    width: 70%;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.que-es {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.que-es .resumen {
  display: flex;
  margin-top: 1rem;
  border-radius: 20px;
  padding: 1rem;
  width: 95%;
  background-color: #E95079;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  height: auto;
}
.que-es .resumen .contenedor-texto {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: clamp(200px, 90%, 500px);
  justify-content: center;
}
.que-es .resumen .contenedor-texto h1 {
  text-align: center;
  font-size: clamp(1.4rem, 5vw, 2.5rem);
  color: white;
  font-weight: 1100;
}
.que-es .resumen .contenedor-texto p {
  font-size: clamp(0.9rem, 3vw, 1.1rem);
  color: white;
}
.que-es .resumen .gird-imagenes {
  overflow-y: hidden;
  width: clamp(200px, 90%, 500px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(10px, 5%, 20px);
  grid-template-rows: repeat(3, 1fr);
  padding-bottom: 1rem;
  padding: 2rem;
}
.que-es .resumen .gird-imagenes img {
  width: 95%;
  animation: transformar 1s ease-in-out;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1/1.12;
  background-color: #f2f2f2;
  border-radius: 1rem;
}
.que-es .objetivo {
  display: flex;
  margin-top: 1rem;
  border-radius: 20px;
  padding: 1rem;
  width: 95%;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  height: auto;
}
.que-es .objetivo .img {
  width: 80%;
  max-width: 420px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.que-es .objetivo .contenedor-texto {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  padding: 2rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.que-es .objetivo .contenedor-texto h2 {
  font-size: clamp(1.4rem, 5vw, 2.5rem);
  color: #E95079;
  text-align: center;
}
.que-es .objetivo .contenedor-texto p {
  font-size: clamp(0.9rem, 3vw, 1.1rem);
  text-align: center;
  color: #646464;
}
.que-es .objetivo .contenedor-texto p span {
  font-weight: 600;
  color: #E95079;
}
.que-es .beneficios {
  padding: 2rem;
  background-color: #FBD0D9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.que-es .beneficios h2 {
  width: 90%;
  text-align: center;
  max-width: 700px;
  font-size: clamp(1.4rem, 5vw, 2.3rem);
  color: #BC2449;
}
.que-es .beneficios p {
  width: 90%;
  text-align: center;
  color: #646464;
  font-weight: 500;
}
.que-es .beneficios .contenedor-beneficios {
  display: grid;
  gap: 1rem;
  width: 80%;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.que-es .beneficios .contenedor-beneficios .beneficio {
  background-color: white;
  padding: 0.4rem 1rem;
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.que-es .beneficios .contenedor-beneficios .beneficio .icono {
  width: 40px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5px;
}
.que-es .beneficios .contenedor-beneficios .beneficio h4 {
  font-size: clamp(0.75rem, 3vw, 0.9rem);
  font-weight: 500;
  color: #921F3F;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.que-es .beneficios .contenedor-beneficios .beneficio h4 h3 {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  color: #E95079;
}
.que-es .beneficios .contenedor-beneficios .beneficio h4 p {
  font-size: clamp(0.7rem, 3vw, 1.1rem);
  color: #646464;
}
.que-es .colaboradores {
  background-color: #D6506D;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.que-es .colaboradores img {
  width: 90%;
  max-width: 250px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 1090px) {
  .que-es .beneficios {
    width: 100%;
  }
  .que-es .beneficios .contenedor-beneficios {
    width: 100%;
    grid-template-columns: 1fr;
  }
}
.titulo {
  margin-top: 70px;
  color: #E44F69;
  font-size: 2rem;
  text-align: center;
}

.preguntas {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding-bottom: 20px;
}

.pregunta-frecuente {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 900px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.pregunta-frecuente:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.pregunta-frecuente .icono {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.pregunta-frecuente .pregunta {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 80%;
  gap: 1rem;
}
.pregunta-frecuente .pregunta p {
  font-size: clamp(0.8rem, 2.5vw, 1.1rem);
}
.pregunta-frecuente .pregunta img {
  max-width: 80px;
}
.pregunta-frecuente .respuesta {
  display: flex;
  color: #1e1e1e;
  font-style: normal;
  align-items: center;
  text-align: left;
  font-weight: 400;
  overflow: hidden;
  max-height: 0px;
  height: auto;
  width: 90%;
  transition: all 0.5s ease-in-out;
}
.pregunta-frecuente .respuesta p {
  font-size: clamp(0.8rem, 2.5vw, 1.1rem);
}
.pregunta-frecuente .boton-preguntas {
  display: flex;
  justify-content: right;
  align-items: right;
  stroke-width: 70px !important;
  stroke: #292d32 !important;
}

@media screen and (max-width: 900px) {
  .preguntas {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    text-align: center;
    flex-wrap: wrap;
  }
  .Mapas .Mapa-CUU {
    width: 100%;
    height: 300px;
  }
  .Mapas .Mapa-JRZ {
    width: 100%;
    height: 300px;
  }
}
.Contenedor-contacto {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.Contenedor-contacto .Contactanos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 50px;
}
.Contenedor-contacto .Contactanos h1 {
  font-size: clamp(1.4rem, 5vw, 2.5rem);
  color: #E95079;
  text-align: left;
}
.Contenedor-contacto .Contactanos h2 {
  font-size: clamp(1.4rem, 5vw, 1.6rem);
  color: #E95079;
  text-align: left;
}
.Contenedor-contacto .Contactanos p {
  font-size: clamp(0.9rem, 3vw, 1.1rem);
  color: black;
}
.Contenedor-contacto .Contactanos .Formulario {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
}
.Contenedor-contacto .Contactanos .boton-contacto {
  margin-top: 20px;
  background-color: #E95079;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.Contenedor-contacto .Mapas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
}
.Contenedor-contacto .Mapas h2 {
  font-size: clamp(1.4rem, 5vw, 1.6rem);
  color: #232323;
  text-align: left;
}
.Contenedor-contacto .Mapas .Mapa-CUU {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  background-color: #f2f2f2;
  animation: fade 1s ease-in-out;
}
.Contenedor-contacto .Mapas .Mapa-JRZ {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  background-color: #f2f2f2;
  animation: fade 1s ease-in-out;
}

@media screen and (max-width: 1500px) {
  .Contenedor-contacto {
    flex-direction: column;
    gap: 1rem;
  }
  .Contenedor-contacto .Contactanos {
    padding: 20px;
  }
  .Contenedor-contacto .Contactanos .Formulario {
    gap: 1rem;
  }
}
.auth {
  display: flex;
  justify-content: center;
  padding-top: 2vh;
}
.auth .auth-container {
  animation: show 0.2s ease-in;
  width: 95%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 5rem);
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}
.auth .auth-container .contenedor-arte {
  width: 40%;
  height: 100%;
}
.auth .auth-container .contenedor-arte img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.auth .auth-container .contenedor-formulario {
  animation: fade 1.3s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  gap: 1rem;
}
.auth .auth-container .contenedor-formulario h1 {
  text-align: center;
  font-size: clamp(1.5rem, 2vw, 2.3rem);
}
.auth .auth-container .contenedor-formulario h1 span {
  color: #D6506D;
  font-weight: 800;
}
.auth .auth-container .contenedor-formulario form {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  gap: 1rem;
}
.auth .auth-container .contenedor-formulario form p {
  font-weight: 500;
  color: #D6506D;
  text-align: center;
}
.auth .auth-container .contenedor-formulario form p:hover {
  cursor: pointer;
  color: #921F3F;
}
.auth .auth-container .contenedor-formulario hr {
  width: 20%;
  opacity: 0.2;
}
.auth .auth-container .contenedor-formulario p span {
  color: #D6506D;
  font-weight: 500;
}
.auth .auth-container .contenedor-formulario p span:hover {
  cursor: pointer;
  color: #921F3F;
}

.verificacion {
  display: flex;
  padding: 2rem;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  gap: clamp(1rem, 2vw, 2rem);
  align-items: center;
  justify-content: center;
}
.verificacion h1 {
  color: #D6506D;
  font-weight: 650;
  font-size: clamp(1.2rem, 4vw, 2.6rem);
}
.verificacion .contenedor-verificacion {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: clamp(1rem, 2vw, 2rem);
  border-radius: 20px;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: clamp(1rem, 2vw, 2rem);
}
.verificacion .contenedor-verificacion h3 {
  font-size: clamp(1rem, 2vw, 1.8rem);
  font-weight: 500;
  color: black;
  text-align: center;
}
.verificacion .contenedor-verificacion p {
  text-align: center;
}
.verificacion .contenido-verificacion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  overflow-x: visible !important;
  overflow-y: visible !important;
}
.verificacion .contenido-verificacion .input-verificacion {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
.verificacion span {
  font-weight: 500;
  color: #D6506D;
}
.verificacion span:hover {
  cursor: pointer;
  color: #921F3F;
}
.verificacion .texto-reenvio {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
}
.verificacion .btn-verificacion:hover {
  background-color: #921F3F;
}

.recuperacion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95vh;
  padding: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  gap: 2rem;
}
.recuperacion h1 {
  color: #D6506D;
  font-weight: 650;
  font-size: clamp(1.2rem, 4vw, 2.6rem);
}
.recuperacion .recuperacion-correo {
  display: flex;
  flex-direction: column;
  animation: fade 1.3s ease-in;
  gap: 1.5rem;
  padding: 4rem;
  background-color: #f2f2f2;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.recuperacion .recuperacion-contrasena {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  animation: fade 1.3s ease-in;
  display: none;
  padding: 4rem;
  background-color: #f2f2f2;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.recuperacion .input-recuperacion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}
.recuperacion .input-contrasena {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 230px !important;
}
.recuperacion .contenido-verificacion {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.recuperacion .boton-recuperacion {
  background-color: #D6506D;
}
.recuperacion .boton-verificacion {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #D6506D;
  width: 40%;
}

@media screen and (max-width: 1790px) {
  .auth .auth-container {
    flex-direction: column;
  }
  .auth .auth-container .contenedor-arte {
    display: none;
  }
  .auth .auth-container .contenedor-movil {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }
  .auth .auth-container .contenedor-movil h1 {
    text-align: center;
    font-size: clamp(1.5rem, 2vw, 2.5rem);
  }
  .auth .auth-container .contenedor-movil h1 span {
    color: #D6506D;
    font-weight: 800;
  }
  .auth .auth-container .contenedor-formulario {
    width: 100%;
    height: 56vh;
    overflow: hidden;
  }
  .auth .contenedor-formulario h1 {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .input-recuperacion {
    flex-direction: column !important;
    gap: 1rem;
    width: 100%;
  }
}
.nav-portal {
  background-color: #D6506D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: white;
}
.nav-portal .contendor-opciones {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nav-portal .contendor-opciones * {
  font-size: clamp(0.7rem, 2vw, 1rem);
  font-weight: 500;
}
.nav-portal .contendor-opciones *:hover {
  cursor: pointer;
  color: #e8e8e8;
}

.landing-portal {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.landing-portal h1 {
  text-align: center;
}
.landing-portal .contenedor-etapas-solicitud {
  margin-top: 1rem;
  display: flex;
  width: 90%;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.landing-portal .contenedor-etapas-solicitud .etapa-solicitud {
  width: 90%;
  max-width: 400px;
  transition: all 0.3s ease-in-out;
  display: flex;
  background-color: rgba(251, 108, 180, 0.2);
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}
.landing-portal .contenedor-etapas-solicitud .etapa-solicitud:hover {
  cursor: pointer;
  transform: scale(1.02);
  background-color: rgba(251, 108, 180, 0.34);
}
.landing-portal .contenedor-etapas-solicitud .alerta {
  transition: all 0.3s ease-in-out;
  transform: scale(1.02);
  background-color: rgba(251, 108, 180, 0.5);
}
.landing-portal .contenedor-etapas-solicitud .bloqueado {
  transition: all 0.3s ease-in-out;
  transform: scale(1.02);
  background-color: #e8e8e8;
}
.landing-portal .contenedor-etapas-solicitud .bloqueado:hover {
  cursor: not-allowed;
}
.landing-portal .error-alerta {
  animation: show 0.5s ease-in-out;
  color: #E41E73;
}

@keyframes transformar {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}

h1 {
  font-family: "Red Hat Display", sans-serif;
}

.swal2-icon {
  overflow: visible;
}

.MuiFormControl-root {
  overflow: visible !important;
}
.MuiFormControl-root * {
  overflow: visible !important;
}/*# sourceMappingURL=index.css.map */